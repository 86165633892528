
import { getOssUrl, getTodayNewsName } from '@/libs/utils';
import { MessageBox } from 'element-ui';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class PreviewNewsMiniprogram extends Vue {
    private miniUrl = '';
    @Prop() activityId!: string;

    created() {
        const today = getTodayNewsName();
        if (Number(today.slice(-2)) < 5) {
            MessageBox.confirm('小程序活动ID该更换了朋友', '重要！！！！！！！！');
        } else if (!this.activityId) {
            MessageBox.confirm('活动ID不可为空哦~');
        }
        this.miniUrl = `pages/Readtoday/index?activityId=${this.activityId}&amp;refer=article&amp;readDate=${today}`;
    }

    private surl = getOssUrl('/common/readtoday_banner.png');
}
